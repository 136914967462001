import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input } from 'antd';
import { useNavigate } from "react-router-dom";
import { ChangePassword } from "../../../../apis/apis";
import { token } from "../../../../configs/localStorage";
import { Notification } from "../../../../configs/notification";

const ModalChangePasswordAuth = ({ openModalChangePasswordAuth, setOpenModalChangePasswordAuth }: any) => {
    const [form] = Form.useForm();

    const navigate = useNavigate()
    const user: any = localStorage.getItem("user")
    const onFinish = async (value: any) => {
        try {
            const formData = new FormData();
            formData.append('Token', token);
            formData.append('Account', user);

            formData.append('OldPass', value?.OldPass);
            formData.append('NewPass', value?.password);
            const res = await ChangePassword(formData)
            if (res?.data?.status === 0) {
                Notification("success", res?.data?.message)
            } else {
                Notification("success", res?.data?.message)
            }
        } catch (e) {

        }
    }

    return (
        <Modal
            centered
            open={openModalChangePasswordAuth}
            onOk={() => setOpenModalChangePasswordAuth(false)}
            onCancel={() => setOpenModalChangePasswordAuth(false)}
            width={470}
            footer={false}
            className="modal_login"
        >
            <h2 className="mb-2">Đổi mật khẩu</h2>
            <Form
                form={form}

                onFinish={onFinish}
                layout="vertical"
            >
                <Form.Item
                    label="Nhập mật khẩu hiện tại"
                    name="OldPass"
                    className="text-[16px] input-number"
                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu hiện tại !' }]}
                >
                    <Input className="h-[50px] text-[#646464] " placeholder="mật khẩu hiện tại " />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Mật khẩu mới"
                    className="text-[16px]"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập mật khẩu mới!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password className="h-[50px] text-[#646464]  " placeholder="mật khẩu  mới " />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Xác nhận lại mật khẩu"
                    dependencies={['password']}
                    hasFeedback
                    className="text-[16px]"
                    rules={[
                        {
                            required: true,
                            message: 'Vui lòng nhập xác nhận mật khẩu !',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Mật khẩu của bạn không trùng khớp!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password className="h-[50px] text-[#646464]  " placeholder="xác nhận lại mật khẩu " />
                </Form.Item>
                {/* <Form.Item
                    label="Nhập mật khẩu mới "
                    name="password"
                    className="text-[16px]"
                    rules={[{ required: true, message: 'Vui lòng nhập mật khẩu mới !' }]}
                >
                    <Input.Password className="h-[50px] text-[#646464]  " placeholder="Mật khẩu mới " />
                </Form.Item>
                <Form.Item
                    label="xác nhận  mật khẩu"
                    name="passwordConfirm"
                    className="text-[16px]"
                    rules={[{ required: true, message: 'Vui lòng xác nhận  mật khẩu !' }]}
                >
                    <Input.Password className="h-[50px] text-[#646464]  " placeholder="Mật khẩu xác nhận " />
                </Form.Item> */}

                <Form.Item className="flex justify-center mt-5" >
                    <Button className="bg-[#0D72BB] h-[53px] text-[16px] rounded-[10px]  text-[#fff] w-[250px] " htmlType="submit">
                        Đổi mật khẩu
                    </Button>
                </Form.Item>
            </Form >
        </Modal >
    )
}
export default ModalChangePasswordAuth