import React, { lazy } from "react";
const cardRegistration = React.lazy(
  () => import("../components/organisms/pages/Dang_ky_the_Xe")
);
// const giaHanTheXe = React.lazy(() => import('../components/organisms/pages/Gia_han_the_xe'))

export const routePortals = {
  DANG_KY_THE_XE: "/dang-ky-the-xe",
  GIA_HAN_THE_XE: "/gia-han-the-xe",
  DEFAULT: "*",
};
export const routePortal = [
  {
    id: 2,
    route: routePortals.DANG_KY_THE_XE,
    component: cardRegistration,
  },
];
