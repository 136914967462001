import React, { useEffect, useState } from "react";
import login from "./../../../assets/icon/login.svg";
import loginHover from "./../../../assets/icon/loginHover.svg";
import menuBlue from "./../../../assets/icon/menuBlue.svg";
import menutrang from "./../../../assets/icon/menutrang.svg";

import x from "./../../../assets/icon/x.svg";
import menuAdmin from "./../../../assets/icon/menuAdmin.svg";
import benphai from "./../../../assets/icon/benphai.svg";
import { Link, useLocation } from "react-router-dom";
import { routePortals } from "../../../routes/PortalRoute";
import { Button, Drawer, Dropdown } from "antd";
import Login from "./Modal/Login";

import { getCurrentDateTime } from "../../../configs/common";
import {
  routePortalAdmin,
  routePortalsAdmin,
} from "../../../routes/PortalRouteAdmin";
import Auth from "./Auth";
import Time from "./Time";
import { role, token } from "../../../configs/localStorage";
import { GetStatusUseOnlinePayment } from "../../../apis/apis";

const Header = () => {
  const [openModalLogin, setOpenModalLogin] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  const [IsStatusShowPayment, setIsStatusShowPayment] = useState(false);
  const getStatusSwitch = async () => {
    try {
      const res = await GetStatusUseOnlinePayment();
      if (res?.data) {
        setIsStatusShowPayment(res?.data?.message === 1 ? true : false);
      }
    } catch (e) {
    } finally {
    }
  };
  useEffect(() => {
    getStatusSwitch();
  }, []);
  const location = useLocation();
  const items = [
    {
      key: "1",
      label: (
        <div className=" text-[32px] mb-2 text-center ">Parking Management</div>
      ),
    },
    {
      key: "2",
      label: (
        <Link
          className="flex gap-2 text-[20px] mb-3 hover-nav "
          to={routePortalsAdmin.QUAN_LY_THE_XE}
        >
          <img src={menuAdmin} alt="" /> Quản lý thẻ xe
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link
          className="flex gap-2 text-[20px] mb-3 hover-nav  "
          to={routePortalsAdmin.QUAN_LY_TAI_KHOAN}
        >
          <img src={menuAdmin} alt="" /> Quản lý tài khoản
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <Link
          className="flex gap-2 text-[20px] mb-3 hover-nav  "
          to={routePortalsAdmin.DANG_KY_GIA_HAN}
        >
          <img src={menuAdmin} alt="" /> Đăng ký-Gia hạn vé
        </Link>
      ),
    },
    {
      key: "5",
      label: (
        <Link
          className="flex gap-2 text-[20px] mb-3 hover-nav  "
          to={routePortalsAdmin.LICH_SU_THANH_TOAN}
        >
          <img src={menuAdmin} alt="" /> Lịch sử thanh toán
        </Link>
      ),
    },
    {
      key: "6",
      label: (
        <Link
          className="flex gap-2 text-[20px] mb-3 hover-nav  "
          to={routePortalsAdmin.TRA_CUU_THONG_TIN}
        >
          <img src={menuAdmin} alt="" /> Tra cứu thông tin
        </Link>
      ),
    },
    {
      key: "7",
      label: (
        <Link
          className="flex gap-2 text-[20px]  mb-3 hover-nav "
          to={routePortalsAdmin.CAU_HINH_THANH_TOAN}
        >
          <img src={menuAdmin} alt="" />
          Cấu hình thanh toán
        </Link>
      ),
    },
    {
      key: "8",
      label: (
        <Link
          className="flex gap-2 text-[20px] mb-3 hover-nav  "
          to={routePortalsAdmin.THONG_KE_DOANH_THU}
        >
          <img src={menuAdmin} alt="" />
          Thống kê doanh thu
        </Link>
      ),
    },
  ];
  return (
    <header className=" ">
      {token ? (
        <div className="bg-[#0D72BB]">
          <div className="container">
            <div className="flex justify-between items-center h-[50px]  min-[500px]:h-[40px] text-[20px] text-[#fff] ">
              {
                <>
                  <div className="flex gap-2 items-center">
                    <Dropdown
                      trigger={["click"]}
                      menu={{ items }}
                      placement="bottomLeft"
                      overlayClassName="custom-menu"
                    >
                      <div className="flex items-center py-3  h-full cursor-pointer">
                        <img src={menutrang} alt="" />
                      </div>
                    </Dropdown>
                    <Link to={routePortals.GIA_HAN_THE_XE}> TRANG CHỦ </Link>
                  </div>
                  <div className="flex min-[500px]:gap-4 items-center max-[500px]:flex-col  h-full ">
                    <span>{getCurrentDateTime("day")}</span>
                    <span>
                      <Time />
                    </span>
                  </div>
                </>
              }

              <Auth />
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-[#fff] lg:bg-[#0D72BB]">
          <div className="container">
            <div className="flex h-[40px] px-10  ">
              <div className="w-[90%] lg:w-[80%] flex justify-center">
                <ul className="flex ">
                  {IsStatusShowPayment || token ? (
                    <li
                      className={` ${
                        location.pathname === routePortals.GIA_HAN_THE_XE
                          ? "text-[#72BB0D] "
                          : "text-[#fff] max-lg:hidden "
                      } hover:bg-[#fff] hover:text-[#72BB0D]  `}
                    >
                      <Link
                        className="text-[20px] py-[5px] inline-block px-8"
                        to={routePortals.GIA_HAN_THE_XE}
                      >
                        Gia hạn thẻ xe
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  <li
                    className={` ${
                      location.pathname === routePortals.DANG_KY_THE_XE
                        ? "text-[#72BB0D] "
                        : "text-[#fff] max-lg:hidden"
                    } hover:bg-[#fff] hover:text-[#72BB0D] `}
                  >
                    <Link
                      className="text-[20px] py-[5px] inline-block px-8"
                      to={routePortals.DANG_KY_THE_XE}
                    >
                      {" "}
                      Đăng ký thẻ xe
                    </Link>
                  </li>
                </ul>
              </div>

              <div className=" w-[10%] lg:w-[20%] flex justify-end ">
                <div
                  onClick={() => setOpenModalLogin(true)}
                  className="hidden btn-login cursor-pointer hover:text-[#72BB0D] lg:flex h-full text-[20px] text-[#fff]  gap-1 items-center"
                >
                  <img className="login" src={login} alt="" />
                  <img className="login-hover" src={loginHover} alt="" />
                  Đăng nhập
                </div>
                <div
                  onClick={showDrawer}
                  className=" flex items-center h-full lg:hidden"
                >
                  <img src={menuBlue} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Drawer
        width={480}
        title={
          <div className="flex justify-end py-3">
            <div className="cursor-pointer">
              <img onClick={onClose} src={x} alt="" />
            </div>
          </div>
        }
        placement="left"
        closable={false}
        onClose={onClose}
        open={openDrawer}
        getContainer={false}
      >
        <ul>
          <li className=" ">
            <Link
              onClick={onClose}
              to={routePortals.GIA_HAN_THE_XE}
              className="text-[20px] text-[#4D4D4D] flex py-3 px-1 justify-between items-center"
            >
              Gia hạn thẻ xe <img src={benphai} alt="" />
            </Link>
          </li>
          <li className=" ">
            <Link
              onClick={onClose}
              to={routePortals.DANG_KY_THE_XE}
              className="text-[20px] text-[#4D4D4D] flex py-3 px-1 justify-between items-center"
            >
              Đăng ký thẻ xe <img src={benphai} alt="" />
            </Link>
          </li>
          <li className=" ">
            <div
              onClick={() => {
                onClose();
                setOpenModalLogin(true);
              }}
              className="text-[20px] text-[#4D4D4D] flex py-3 px-1 justify-between items-center"
            >
              Đăng nhập <img src={benphai} alt="" />
            </div>
          </li>
        </ul>
      </Drawer>
      <Login
        openModalLogin={openModalLogin}
        setOpenModalLogin={setOpenModalLogin}
      />
    </header>
  );
};
export default Header;
