import { Dropdown } from "antd";
import React, { useState } from "react";
import user from "./../../../assets/icon/user.svg";
import logout from "./../../../assets/icon/logout.svg";
import changePassword from "./../../../assets/icon/changePassword.svg";
import logoutHover from "./../../../assets/icon/logoutHover.svg";
import changePasswordHover from "./../../../assets/icon/changePasswordHover.svg";
import ModalChangePasswordAuth from "./Modal/ModalChangePasswordAuth";
import { Logout } from "../../../apis/apis";
import { token } from "../../../configs/localStorage";
import { Notification } from "../../../configs/notification";
import { useNavigate } from "react-router-dom";
import { routePortals } from "../../../routes/PortalRoute";
const Auth = () => {
  const [openModalChangePasswordAuth, setOpenModalChangePasswordAuth] =
    useState(false);
  const OpenModalChangePassword = () => {
    setOpenModalChangePasswordAuth(true);
    setOpen(false);
  };
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      const body = new FormData();
      body.append("Token", token);

      const res = await Logout(body);
      if (res?.data?.status === 0) {
        Notification("success", res?.data?.message);
        localStorage.clear();
        window.location.href = routePortals.DANG_KY_THE_XE;
      }
    } catch (e) {}
  };

  const items = [
    {
      key: "11",
      label: (
        <div
          onClick={OpenModalChangePassword}
          className=" change_password flex gap-2 text-[20px] hover:text-[#72BB0D]  py-1 "
        >
          <img className="change_password-icon" src={changePassword} alt="" />
          <img
            className="change_password-icon-hover"
            src={changePasswordHover}
            alt=""
          />
          <span>Đổi mật khẩu</span>
        </div>
      ),
    },
    {
      key: "10",
      label: (
        <div
          onClick={handleLogout}
          className=" logout flex gap-2 text-[20px] hover:text-[#72BB0D] py-1 "
        >
          <img className="logout-icon" src={logout} alt="" />
          <img className="logout-icon-hover" src={logoutHover} alt="" />
          <span>Đăng xuất</span>
        </div>
      ),
    },
  ];
  return (
    <>
      <Dropdown
        trigger={["click"]}
        menu={{ items }}
        placement="bottomLeft"
        overlayClassName="auth-menu"
        className="cursor-pointer"
        open={open}
      >
        <div
          onClick={() => setOpen(!open)}
          className="flex items-center gap-2 h-full"
        >
          <span>{localStorage.getItem("user")}</span>
          <img src={user} alt="" />
        </div>
      </Dropdown>
      <ModalChangePasswordAuth
        openModalChangePasswordAuth={openModalChangePasswordAuth}
        setOpenModalChangePasswordAuth={setOpenModalChangePasswordAuth}
      />
    </>
  );
};
export default Auth;
