import moment from 'moment';
export function generateRandomString() {
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomString = '';

    for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters.charAt(randomIndex);
    }

    return randomString;
}


export const getCurrentDateTime = (type: any) => {
    const currentMoment = moment();
    if (type === "day") {
        const formattedDateTime = currentMoment.format('MM/DD/YYYY');
        return formattedDateTime
    }
    const formattedDateTime = currentMoment.format('HH:mm:ss');
    return formattedDateTime;
};

export const formatNumber = (num: number, unit: string = 'VNĐ') => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " " + unit
export const total = (x: number, y: number) => {
    let total = 0;
    total += x + y
    return formatNumber(total)
}
export const formatNumberNoVND = (num: number) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " " 
