import { instanceAxios } from "../configs/axios";

export const login = (body: any) => instanceAxios.post("/api/Login", body);
export const Logout = (body: any) => instanceAxios.post("api/Logout", body);
export const ChangePassword = (body: any) =>
  instanceAxios.post("api/ChangePassword", body);

// quản lý thẻ xe
export const ReadListCard = (body: any) =>
  instanceAxios.post("api/ReadListCard", body);
export const CreateCard = (body: any) =>
  instanceAxios.post("api/CreateCard", body);
export const UpdateCard = (body: any) =>
  instanceAxios.post("api/UpdateCard", body);
export const DeleteCard = (body: any) =>
  instanceAxios.post("api/DeleteCard", body);
export const DeleteInforMonthCard = (body: any) =>
  instanceAxios.post("api/DeleteInforMonthCard", body);
export const ImportCardMonth = (body: any) =>
  instanceAxios.post("api/ImportCardMonth", body);
// loại xe
// Đăng ký gia hạn thẻ xe
export const ReadListVehicleMonth = (body: any) =>
  instanceAxios.post("api/ReadListVehicleMonth", body);
export const UpdateInforVehicleMonth = (body: any) =>
  instanceAxios.post("api/UpdateInforVehicleMonth", body);
export const CancelMonthCard = (body: any) =>
  instanceAxios.post("api/CancelMonthCard", body);
export const ExtendMonthCard = (body: any) =>
  instanceAxios.post("api/ExtendMonthCard", body);
export const ReadListVehicleExpired = (body: any) =>
  instanceAxios.post("api/ReadListVehicleExpired", body);

// thông kê doanh thu
export const ReadRevenueStatistics = (body: any) =>
  instanceAxios.post("api/ReadRevenueStatistics", body);

// đăng ký thẻ xe
export const UserRegisterCard = (body: any) =>
  instanceAxios.post("api/UserRegisterCard", body);
export const ReloadQr = (body: any) =>
  instanceAxios.post("api/ReCreateQR", body);

export const ReadListTypeVehicle = (body: any) =>
  instanceAxios.post("api/ReadListTypeVehicle", body);

// đăng ký thẻ thang
export const ListRequestCreateMonthCard = (body: any) =>
  instanceAxios.post("api/ListRequestCreateMonthCard", body);
export const DeleteQueueRegisterCard = (body: any) =>
  instanceAxios.post("api/DeleteQueueRegisterCard", body);
export const CheckCardMonthExist = (body: any) =>
  instanceAxios.post("api/CheckCardMonthExist", body);
export const ReadListUserByPhone = (body: any) =>
  instanceAxios.post("api/ReadListUserByPhone", body);
export const GetListCardMonthNotRegister = (body: any) =>
  instanceAxios.post("api/GetListCardMonthNotRegister", body);
export const RegisterMonthCard = (body: any) =>
  instanceAxios.post("api/RegisterMonthCard", body);
// get all bank
export const getAllBank = (body: any) =>
  instanceAxios.post("/api/ReadListBank", body);
// cấu hình payment
export const ApplyConfig = (body: any) =>
  instanceAxios.post("api/ApplyConfig", body);
export const UserReadDataCardMonth = (body: any) =>
  instanceAxios.post("api/UserReadDataCardMonth", body);

export const GetConfigPayment = (body: any) =>
  instanceAxios.post("api/GetConfigPayment", body);

export const ImportIdCard = (body: any) =>
  instanceAxios.post("api/ImportIdCard", body);

export const CalculatePrice = (body: any) =>
  instanceAxios.post("api/CalculatePrice", body);
export const ExportIdCard = (body: any) =>
  instanceAxios.post("api/ExportIdCard", body);
export const GetDataBillMonth = (body: any) =>
  instanceAxios.post("api/GetDataBillMonth", body);
export const CheckPayBill = (body: any) =>
  instanceAxios.post("api/CheckPayBill", body);
export const PayBill = (body: any) => instanceAxios.post("api/PayBill", body);

export const CreateBill = (body: any) =>
  instanceAxios.post("api/CreateBill", body);
export const ExportCardMonth = (body: any) =>
  instanceAxios.post("api/ExportCardMonth", body);

export const CalculateMoneyExtend = (body: any) =>
  instanceAxios.post("api/CalculateMoneyExtend", body);

export const UpdateStatusPayBill = (body: any) =>
  instanceAxios.post("api/UpdateStatusPayBill", body);
export const ReadListUser = (body: any) =>
  instanceAxios.post("api/ReadListUser", body);
export const ApiCreateUser = (body: any) =>
  instanceAxios.post("api/CreateUser", body);
export const ApiUpdateUser = (body: any) =>
  instanceAxios.post("api/UpdateUser", body);
export const ApiDeleteUser = (body: any) =>
  instanceAxios.post("api/DeleteUser", body);
export const ReadHistoryBank = (body: any) =>
  instanceAxios.post("api/ReadHistoryBank", body);
export const CheckPaymentOnlineNow = (body: any) =>
  instanceAxios.post("api/CheckPaymentOnlineNow", body);
export const GetDataAccountBank = (body: any) =>
  instanceAxios.post("api/GetDataAccountBank", body);

export const updateStatusCaptcha = (body: any) =>
  instanceAxios.post("api/SetDisplayCapcha", body);
export const getStatusCaptcha = () => instanceAxios.get("api/GetDisplayCapcha");
/// tra cứu thông tin
export const getReadInforRegisterVehicle = (body: any) =>
  instanceAxios.post("api/ReadInforRegisterVehicle", body);

// lấy trạng thái bật tắt gia hạn online export const getReadInforRegisterVehicle = (body: any) =>

export const GetStatusUseOnlinePayment = () =>
  instanceAxios.get("api/GetStatusUseOnlinePayment");
export const SetUseOnlinePayment = (body: any) =>
  instanceAxios.post("api/SetUseOnlinePayment", body);

/// update 1.1.9
export const GetTypeServer = () => instanceAxios.get("api/GetTypeServer");
export const ImportCardSupplier = (body: any) =>
  instanceAxios.post("api/ImportCardSupplier", body);
